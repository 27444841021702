import React from "react";
import { Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import useMediaQuery from "../../../hooks/useMediaQuery ";
import css from "../home.module.css";
import SectionZone from "../SectionZone";

const img = "/assets/img/map.webp";
const img2 = "/assets/img/map_mob.webp";
export default function BannerIlha({ infoJson }) {
  const ismobile = useMediaQuery("(max-width: 991px)");
  return (
    <SectionZone top={10} bottom={0.0001}>
      <Container>
        <Col className={css.BannerIlha}>
          <Link to={infoJson.Aluguer.url}>
            <img
              src={ismobile ? img2 : img}
              className={css.BannerIlha}
              alt="importar e exportar para ilhas"
            />
          </Link>
        </Col>
      </Container>
    </SectionZone>
  );
}
