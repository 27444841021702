import { useEffect } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { atualizarPesquisa, LimparToUrl } from "../helpers/tratarInfoCarros";

const useVerificaUrlPesquisa = (
  ArrayCarros,
  tipo,
  url,
  setFilteredCarros,
  setPesquisaMarcas,
  setPesquisaMarcasSelect,
  setModelos,
  setPesquisaModelos,
  setPesquisaModelosSelect,
  setPesquisaCombustivel,
  setPesquisaCombustivelSelect,
  setPesquisaTransmissao,
  setPesquisaTransmissaoSelect,
  setPesquisaSegmento,
  setPesquisaSegmentoSelect,
  setPesquisaExtras,
  setPesquisaAnoInicial,
  setPesquisaAnoFinal,
  setPesquisaKmInicial,
  setPesquisaKmFinal,
  setPesquisaPrecoInicial,
  setPesquisaPrecoFinal,
  setPesquisaPotenciaInicial,
  setPesquisaLotacao,
  setPesquisaLotacaoSelect,
  infoJson,
  allLista
) => {
  let { marca, modelo } = useParams();
  const [paramsBusca] = useSearchParams();

  useEffect(() => {
    const VerificaURL = () => {
      const AnoInicialUrl = paramsBusca.get("AnoInicial");
      const AnoFinalUrl = paramsBusca.get("AnoFinal");
      const PrecoInicialUrl = paramsBusca.get("PrecoInicial");
      const PrecoFinalUrl = paramsBusca.get("PrecoFinal");
      const MensalidadeInicialUrl = paramsBusca.get("MensalidadeInicial");
      const MensalidadeFinalUrl = paramsBusca.get("MensalidadeFinal");
      const KmInicialUrl = paramsBusca.get("KmInicial");
      const KmFinalUrl = paramsBusca.get("KmFinal");
      const CombustivelUrl = paramsBusca.get("Combustivel");
      const SegmentoUrl = paramsBusca.get("Segmento");
      const TransmissaoUrl = paramsBusca.get("Transmissao");
      const PotenciaInicialUrl = paramsBusca.get("PotenciaInicial");
      const ExtrasUrl = paramsBusca.get("Extras");
      const ordenarUrl = paramsBusca.get("Order");
      const pontoVendaUrl = paramsBusca.get("PntVenda");

      const LotacaoUrl = paramsBusca.get("Lotacao");

      let filterMarca = [];
      let filterModelo = [];
      let combustivel = [];
      let transmissao = [];
      let segmento = [];
      let extras = [];
      let lotacao = [];
      let anoInicial = "";
      let anoFinal = "";
      let precoInicial = "";
      let precoFinal = "";
      let kmInicial = "";
      let kmFinal = "";
      let potenciaInicial = "";
      let mensalidadeInicial = "";
      let mensalidadeFinal = "";
      let ordenar = "";
      let ponto = "";

      if (marca) {
        // cria array
        let arrMarca;
        if (marca.includes("--")) {
          arrMarca = marca.split("--");
        } else {
          arrMarca = [marca];
        }

        arrMarca.forEach((itemMarca) => {
          const filterItemMarca = ArrayCarros.filter(
            (carro) => itemMarca === LimparToUrl(carro.Marca.Nome.toLowerCase())
          )
            .sort(function (a, b) {
              if (a.Marca.Nome < b.Marca.Nome) {
                return -1;
              }
              if (a.Marca.Nome > b.Marca.Nome) {
                return 1;
              }
              return 0;
            })
            .reduce((accum, carro) => {
              const accumulator = [...accum];
              if (
                !accumulator.some((item) => item.Marca.Id === carro.Marca.Id)
              ) {
                accumulator.push(carro);
              }
              return accumulator;
            }, [])
            .map((item) => item.Marca.Id);
          filterMarca = [...filterMarca, ...filterItemMarca];
        });

        setPesquisaMarcas(filterMarca);

        const MarcasSelecionadas = filterMarca
          .toString()
          .split(",")
          .map((codigo) => {
            return {
              Marca: { Id: parseInt(codigo) },
            };
          });

        setPesquisaMarcasSelect(MarcasSelecionadas);

        const Modelos = ArrayCarros.filter((carro) => {
          return (
            filterMarca.includes(carro.Marca.Id) && carro.Vendido === false
          );
        })
          .map(({ Modelo }) => {
            return {
              Modelo: { Id: Modelo.Id, Nome: Modelo.Nome },
            };
          })
          .sort(function (a, b) {
            if (a.Modelo.Nome < b.Modelo.Nome) {
              return -1;
            }
            if (a.Modelo.Nome > b.Modelo.Nome) {
              return 1;
            }
            return 0;
          })
          .reduce((accum, carro) => {
            const accumulator = [...accum];
            if (
              !accumulator.some((item) => item.Modelo.Id === carro.Modelo.Id)
            ) {
              accumulator.push(carro);
            }
            return accumulator;
          }, []);
        setModelos(Modelos);
      }

      if (modelo) {
        // cria array
        let arrModelo;
        if (modelo.includes("--")) {
          arrModelo = modelo.split("--");
        } else {
          arrModelo = [modelo];
        }
        arrModelo.forEach((itemModelo) => {
          const filterItemModelo = ArrayCarros.filter(
            (carro) =>
              itemModelo === LimparToUrl(carro.Modelo.Nome.toLowerCase())
          )
            .sort(function (a, b) {
              if (a.Modelo.Nome < b.Modelo.Nome) {
                return -1;
              }
              if (a.Modelo.Nome > b.Modelo.Nome) {
                return 1;
              }
              return 0;
            })
            .reduce((accum, carro) => {
              const accumulator = [...accum];
              if (
                !accumulator.some((item) => item.Modelo.Id === carro.Modelo.Id)
              ) {
                accumulator.push(carro);
              }
              return accumulator;
            }, [])
            .map((item) => item.Modelo.Id);

          filterModelo = [...filterModelo, ...filterItemModelo];
        });

        setPesquisaModelos(filterModelo);

        const ModelosSelecionadas = filterModelo
          .toString()
          .split(",")
          .map((codigo) => {
            return {
              Modelo: { Id: parseInt(codigo) },
            };
          });

        setPesquisaModelosSelect(ModelosSelecionadas);
      }

      if (pontoVendaUrl) {
        ponto = parseInt(pontoVendaUrl);
      }

      if (CombustivelUrl) {
        combustivel = CombustivelUrl.toString().split(",").map(Number);
        setPesquisaCombustivel(combustivel);
        const combustivelSelecionadas = CombustivelUrl.toString()
          .split(",")
          .map((codigo) => {
            return {
              Combustivel: { Id: parseInt(codigo) },
            };
          });

        setPesquisaCombustivelSelect(combustivelSelecionadas);
      }
      if (TransmissaoUrl) {
        transmissao = TransmissaoUrl.toString().split(",").map(Number);
        setPesquisaTransmissao(transmissao);
        const TransmissaoSelecionadas = TransmissaoUrl.toString()
          .split(",")
          .map((codigo) => {
            return {
              CodTransmissao: parseInt(codigo),
            };
          });

        setPesquisaTransmissaoSelect(TransmissaoSelecionadas);
      }
      if (ExtrasUrl !== null && ExtrasUrl !== "" && ExtrasUrl !== undefined) {
        extras = ExtrasUrl.toString().split(",");
        setPesquisaExtras(extras);
      }

      if (SegmentoUrl) {
        segmento = SegmentoUrl.toString().split(",").map(Number);

        setPesquisaSegmento(segmento);
        const SegmentoSelecionadas = SegmentoUrl.toString()
          .split(",")
          .map((codigo) => {
            return {
              Tipo: { Id: parseInt(codigo) },
            };
          });

        setPesquisaSegmentoSelect(SegmentoSelecionadas);
      }

      if (LotacaoUrl) {
        lotacao = LotacaoUrl.toString().split(",").map(Number);

        setPesquisaLotacao(lotacao);
        const LotacaoSelecionadas = LotacaoUrl.toString()
          .split(",")
          .map((codigo) => {
            return {
              Lugares: { Id: parseInt(codigo) },
            };
          });

        setPesquisaLotacaoSelect(LotacaoSelecionadas);
      }

      if (AnoInicialUrl) {
        anoInicial = parseInt(AnoInicialUrl);
        setPesquisaAnoInicial(anoInicial);
      }
      if (AnoFinalUrl) {
        anoFinal = parseInt(AnoFinalUrl);
        setPesquisaAnoFinal(anoFinal);
      }
      if (PrecoInicialUrl) {
        precoInicial = parseInt(PrecoInicialUrl);
        setPesquisaPrecoInicial(precoInicial);
      }
      if (PrecoFinalUrl) {
        precoFinal = parseInt(PrecoFinalUrl);
        setPesquisaPrecoFinal(precoFinal);
      }
      if (MensalidadeInicialUrl) {
        mensalidadeInicial = MensalidadeInicialUrl;
      }
      if (MensalidadeFinalUrl) {
        mensalidadeFinal = MensalidadeFinalUrl;
      }
      if (KmInicialUrl) {
        kmInicial = parseInt(KmInicialUrl);
        setPesquisaKmInicial(kmInicial);
      }
      if (KmFinalUrl) {
        kmFinal = parseInt(KmFinalUrl);
        setPesquisaKmFinal(kmFinal);
      }

      if (PotenciaInicialUrl) {
        potenciaInicial = parseInt(PotenciaInicialUrl);
        setPesquisaPotenciaInicial(potenciaInicial);
      }

      if (ordenarUrl) {
        ordenar = parseInt(ordenarUrl);
      }

      atualizarPesquisa(
        filterMarca,
        filterModelo,
        combustivel,
        segmento,
        transmissao,
        extras,
        anoInicial,
        anoFinal,
        precoInicial,
        precoFinal,
        kmInicial,
        kmFinal,
        potenciaInicial,
        mensalidadeInicial,
        mensalidadeFinal,
        ordenar,
        ponto,
        ArrayCarros,
        setFilteredCarros,
        infoJson
      );
    };

    // usa pesquisa avançada

    if (tipo.toLowerCase().includes(url.toLowerCase())) {
      VerificaURL();
    }

    // usa pesquisa google

    const VerificaURLRapida = () => {
      const CombustivelUrl = paramsBusca.get("Combustivel");
      const SegmentoUrl = paramsBusca.get("Segmento");
      const TransmissaoUrl = paramsBusca.get("Transmissao");
      const ExtrasUrl = paramsBusca.get("Extras");
      const LotacaoUrl = paramsBusca.get("Lotacao");

      let filterMarca = [];
      let filterModelo = [];
      let extras = [];

      if (marca) {
        // cria array
        let arrMarca;
        if (marca.includes("--")) {
          arrMarca = marca.split("--");
        } else {
          arrMarca = [marca];
        }

        arrMarca.forEach((itemMarca) => {
          const filterItemMarca = ArrayCarros.filter(
            (carro) => itemMarca === LimparToUrl(carro.Marca.Nome.toLowerCase())
          )
            .sort(function (a, b) {
              if (a.Marca.Nome < b.Marca.Nome) {
                return -1;
              }
              if (a.Marca.Nome > b.Marca.Nome) {
                return 1;
              }
              return 0;
            })
            .reduce((accum, carro) => {
              const accumulator = [...accum];
              if (
                !accumulator.some((item) => item.Marca.Id === carro.Marca.Id)
              ) {
                accumulator.push(carro);
              }
              return accumulator;
            }, [])
            .map((item) => item.Marca.Id);
          filterMarca = [...filterMarca, ...filterItemMarca];
        });

        const MarcasSelecionadas = filterMarca
          .toString()
          .split(",")
          .map((codigo) => {
            return {
              Marca: { Id: parseInt(codigo) },
            };
          });
        setPesquisaMarcasSelect([]);
        setPesquisaMarcasSelect(MarcasSelecionadas);
      } else {
        setPesquisaMarcasSelect([]);
      }

      if (modelo) {
        // cria array
        let arrModelo;
        if (modelo.includes("--")) {
          arrModelo = modelo.split("--");
        } else {
          arrModelo = [modelo];
        }
        arrModelo.forEach((itemModelo) => {
          const filterItemModelo = ArrayCarros.filter(
            (carro) =>
              itemModelo === LimparToUrl(carro.Modelo.Nome.toLowerCase())
          )
            .sort(function (a, b) {
              if (a.Modelo.Nome < b.Modelo.Nome) {
                return -1;
              }
              if (a.Modelo.Nome > b.Modelo.Nome) {
                return 1;
              }
              return 0;
            })
            .reduce((accum, carro) => {
              const accumulator = [...accum];
              if (
                !accumulator.some((item) => item.Modelo.Id === carro.Modelo.Id)
              ) {
                accumulator.push(carro);
              }
              return accumulator;
            }, [])
            .map((item) => item.Modelo.Id);

          filterModelo = [...filterModelo, ...filterItemModelo];
        });

        const ModelosSelecionadas = filterModelo
          .toString()
          .split(",")
          .map((codigo) => {
            return {
              Modelo: { Id: parseInt(codigo) },
            };
          });

        setPesquisaModelosSelect(ModelosSelecionadas);
      } else {
        setPesquisaModelosSelect([]);
      }

      if (LotacaoUrl) {
        const LotacaoSelecionadas = LotacaoUrl.toString()
          .split(",")
          .map((codigo) => {
            return {
              Lugares: { Id: parseInt(codigo) },
            };
          });

        setPesquisaLotacaoSelect(LotacaoSelecionadas);
      } else {
        setPesquisaLotacaoSelect([]);
      }

      if (CombustivelUrl) {
        const combustivelSelecionadas = CombustivelUrl.toString()
          .split(",")
          .map((codigo) => {
            return {
              Combustivel: { Id: parseInt(codigo) },
            };
          });

        setPesquisaCombustivelSelect(combustivelSelecionadas);
      } else {
        setPesquisaCombustivelSelect([]);
      }
      if (TransmissaoUrl) {
        const TransmissaoSelecionadas = TransmissaoUrl.toString()
          .split(",")
          .map((codigo) => {
            return {
              CodTransmissao: parseInt(codigo),
            };
          });

        setPesquisaTransmissaoSelect(TransmissaoSelecionadas);
      } else {
        setPesquisaTransmissaoSelect([]);
      }
      if (ExtrasUrl !== null && ExtrasUrl !== "" && ExtrasUrl !== undefined) {
        extras = ExtrasUrl.toString().split(",");
        setPesquisaExtras(extras);
      } else {
        setPesquisaExtras([]);
      }

      if (SegmentoUrl) {
        const SegmentoSelecionadas = SegmentoUrl.toString()
          .split(",")
          .map((codigo) => {
            return {
              Tipo: { Id: parseInt(codigo) },
            };
          });

        setPesquisaSegmentoSelect(SegmentoSelecionadas);
      } else {
        setPesquisaSegmentoSelect([]);
      }
    };
    const query = paramsBusca.get("query");

    if (
      query &&
      query !== "" &&
      url === "usadas" &&
      tipo.toLowerCase().includes("automoveis".toLowerCase())
    ) {
      VerificaURLRapida();
    }

    const VerificaURLHome = () => {
      const SegmentoUrl = paramsBusca.get("Segmento");
      const PrecoInicialUrl = paramsBusca.get("PrecoInicial");
      const PrecoFinalUrl = paramsBusca.get("PrecoFinal");
      const CombustivelUrl = paramsBusca.get("Combustivel");
      const MensalidadeInicialUrl = paramsBusca.get("MensalidadeInicial");
      const MensalidadeFinalUrl = paramsBusca.get("MensalidadeFinal");

      let filterMarca = [];
      let filterModelo = [];
      let combustivel = [];
      let transmissao = [];
      let segmento = [];
      let extras = [];
      let anoInicial = "";
      let anoFinal = "";
      let precoInicial = "";
      let precoFinal = "";
      let kmInicial = "";
      let kmFinal = "";
      let potenciaInicial = "";
      let mensalidadeInicial = "";
      let mensalidadeFinal = "";
      let ordenar = "";
      let ponto = "";

      if (marca) {
        // cria array
        let arrMarca;
        if (marca.includes("--")) {
          arrMarca = marca.split("--");
        } else {
          arrMarca = [marca];
        }

        arrMarca.forEach((itemMarca) => {
          const filterItemMarca = allLista
            .filter(
              (carro) =>
                itemMarca === LimparToUrl(carro.Marca.Nome.toLowerCase())
            )
            .sort(function (a, b) {
              if (a.Marca.Nome < b.Marca.Nome) {
                return -1;
              }
              if (a.Marca.Nome > b.Marca.Nome) {
                return 1;
              }
              return 0;
            })
            .reduce((accum, carro) => {
              const accumulator = [...accum];
              if (
                !accumulator.some((item) => item.Marca.Id === carro.Marca.Id)
              ) {
                accumulator.push(carro);
              }
              return accumulator;
            }, [])
            .map((item) => item.Marca.Id);
          filterMarca = [...filterMarca, ...filterItemMarca];
        });

        setPesquisaMarcas(filterMarca);

        const MarcasSelecionadas = filterMarca
          .toString()
          .split(",")
          .map((codigo) => {
            return {
              Marca: { Id: parseInt(codigo) },
            };
          });

        setPesquisaMarcasSelect(MarcasSelecionadas);

        const Modelos = allLista
          .filter((carro) => {
            return (
              filterMarca.includes(carro.Marca.Id) && carro.Vendido === false
            );
          })
          .map(({ Modelo }) => {
            return {
              Modelo: { Id: Modelo.Id, Nome: Modelo.Nome },
            };
          })
          .sort(function (a, b) {
            if (a.Modelo.Nome < b.Modelo.Nome) {
              return -1;
            }
            if (a.Modelo.Nome > b.Modelo.Nome) {
              return 1;
            }
            return 0;
          })
          .reduce((accum, carro) => {
            const accumulator = [...accum];
            if (
              !accumulator.some((item) => item.Modelo.Id === carro.Modelo.Id)
            ) {
              accumulator.push(carro);
            }
            return accumulator;
          }, []);
        setModelos(Modelos);
      }

      if (modelo) {
        // cria array
        let arrModelo;
        if (modelo.includes("--")) {
          arrModelo = modelo.split("--");
        } else {
          arrModelo = [modelo];
        }
        arrModelo.forEach((itemModelo) => {
          const filterItemModelo = allLista
            .filter(
              (carro) =>
                itemModelo === LimparToUrl(carro.Modelo.Nome.toLowerCase())
            )
            .sort(function (a, b) {
              if (a.Modelo.Nome < b.Modelo.Nome) {
                return -1;
              }
              if (a.Modelo.Nome > b.Modelo.Nome) {
                return 1;
              }
              return 0;
            })
            .reduce((accum, carro) => {
              const accumulator = [...accum];
              if (
                !accumulator.some((item) => item.Modelo.Id === carro.Modelo.Id)
              ) {
                accumulator.push(carro);
              }
              return accumulator;
            }, [])
            .map((item) => item.Modelo.Id);

          filterModelo = [...filterModelo, ...filterItemModelo];
        });

        setPesquisaModelos(filterModelo);

        const ModelosSelecionadas = filterModelo
          .toString()
          .split(",")
          .map((codigo) => {
            return {
              Modelo: { Id: parseInt(codigo) },
            };
          });

        setPesquisaModelosSelect(ModelosSelecionadas);
      }

      if (CombustivelUrl) {
        combustivel = CombustivelUrl.toString().split(",").map(Number);
        const combustivelSelecionadas = CombustivelUrl.toString()
          .split(",")
          .map((codigo) => {
            return {
              Combustivel: { Id: parseInt(codigo) },
            };
          });

        setPesquisaCombustivelSelect(combustivelSelecionadas);
      } else {
        setPesquisaCombustivelSelect([]);
      }

      if (PrecoInicialUrl) {
        precoInicial = parseInt(PrecoInicialUrl);
        setPesquisaPrecoInicial(precoInicial);
      } else {
        setPesquisaSegmentoSelect("");
      }
      if (PrecoFinalUrl) {
        precoFinal = parseInt(PrecoFinalUrl);
        setPesquisaPrecoFinal(precoFinal);
      } else {
        setPesquisaSegmentoSelect("");
      }

      if (SegmentoUrl) {
        segmento = SegmentoUrl.toString().split(",").map(Number);
        const SegmentoSelecionadas = SegmentoUrl.toString()
          .split(",")
          .map((codigo) => {
            return {
              Tipo: { Id: parseInt(codigo) },
            };
          });

        setPesquisaSegmentoSelect(SegmentoSelecionadas);
      } else {
        setPesquisaSegmentoSelect([]);
      }

      if (MensalidadeInicialUrl) {
        mensalidadeInicial = MensalidadeInicialUrl;
      }
      if (MensalidadeFinalUrl) {
        mensalidadeFinal = MensalidadeFinalUrl;
      }
      atualizarPesquisa(
        filterMarca,
        filterModelo,
        combustivel,
        segmento,
        transmissao,
        extras,
        anoInicial,
        anoFinal,
        precoInicial,
        precoFinal,
        kmInicial,
        kmFinal,
        potenciaInicial,
        mensalidadeInicial,
        mensalidadeFinal,
        ordenar,
        ponto,
        allLista,
        setFilteredCarros,
        infoJson
      );
    };

    // usa pesquisa Rapida

    if (
      !query &&
      query === null &&
      url === "usadas" &&
      tipo.toLowerCase().includes("automoveis".toLowerCase())
    ) {
      VerificaURLHome();
    }
  }, [
    ArrayCarros,
    tipo,
    url,
    setFilteredCarros,
    setPesquisaMarcas,
    setPesquisaMarcasSelect,
    setModelos,
    setPesquisaModelos,
    setPesquisaModelosSelect,
    setPesquisaCombustivel,
    setPesquisaCombustivelSelect,
    setPesquisaTransmissao,
    setPesquisaTransmissaoSelect,
    setPesquisaSegmento,
    setPesquisaSegmentoSelect,
    setPesquisaExtras,
    setPesquisaAnoInicial,
    setPesquisaAnoFinal,
    setPesquisaKmInicial,
    setPesquisaKmFinal,
    setPesquisaPrecoInicial,
    setPesquisaPrecoFinal,
    setPesquisaPotenciaInicial,
    setPesquisaLotacao,
    setPesquisaLotacaoSelect,
    paramsBusca,
    marca,
    modelo,
    infoJson,
    allLista,
  ]);
};

export default useVerificaUrlPesquisa;
